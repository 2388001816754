import agentApi from "@/api/agentApi";
import request from '@/libs/request/index'

declare interface IUseHomeData {
  getAgentHomeRanking: (startDate: string, endDate: string) => Promise<any>,
  getAgentHomeStatistics: () => Promise<any>,
  getAgentHomeStatisticsDate: (startDate: string, endDate: string) => Promise<any>,
}


function useHomeData(): IUseHomeData{

  //获取首页分店分成排行
 function getAgentHomeRanking(startDate: string, endDate: string): Promise<any>{
   return request.get(agentApi.agentHomeRanking + `?startDate=${startDate}&endDate=${endDate}`)
  }

  //获取首页统计信息和金额对比图
  function getAgentHomeStatistics(): Promise<any>{
    return request.get(agentApi.agentHomeStatistics)
  }

  //根据时间筛选统计信息
  function getAgentHomeStatisticsDate(startDate: string, endDate: string): Promise<any>{
    return request.get(agentApi.agentHomeStatisticsDate+ `?startDate=${startDate}&endDate=${endDate}`)
  }
  return {
    getAgentHomeRanking,
    getAgentHomeStatistics,
    getAgentHomeStatisticsDate,
  }
}

export {
  IUseHomeData,
  useHomeData
}
